import React, { Fragment, useEffect } from "react";
import { emptyArr } from "../../utils/functionsCommon";
import PropTypes from "prop-types";

const DropDown = ({ options }) => {
  const { getFunction, collection, current, setFunction, loading } = options;

  useEffect(() => {
    getFunction();
    // eslint-disable-next-line
  }, []);

  const selectNoValue = () => (
    <option key="notSelected-1" value="notSelected">
      no values
    </option>
  );
  const selectLoading = () => (
    <option key="notSelected-2" value="notSelected">
      loading ...
    </option>
  );

  const selectPleaseSelect = () => (
    <option key="notSelected-3" value="notSelected">
      Please select.
    </option>
  );
  return (
    <Fragment>
      <select
        name="select_workout"
        className="form-select-sm"
        value={
          current !== null && current._id !== null
            ? current._id
            : "Please Select."
        }
        onChange={(e) => {
          setFunction(e);
        }}
      >
        {!emptyArr(collection)
          ? selectPleaseSelect()
          : loading
          ? selectLoading()
          : selectNoValue()}

        {!emptyArr(collection) && !loading
          ? collection.map((a) => (
              <option key={a.name + "-" + a._id} value={a._id}>
                {a.name}
              </option>
            ))
          : ""}
      </select>
    </Fragment>
  );
};

DropDown.propTypes = {
  options: PropTypes.object.isRequired,
};

export default DropDown;
