import {
  GET_SESSION,
  GET_SESSIONS,
  GET_SESSION_COUNT,
  ADD_SESSION,
  UPDATE_SESSION,
  DELETE_SESSION,
  DELETE_SESSIONS,
  SET_CURRENT_SESSION,
  CLEAR_CURRENT_SESSION,
  SESSION_ERROR,
  CLEAR_ERRORS,
  SET_DELETE_SESSION,
  CLEAR_DELETE_SESSION,
  SET_SESSION_ALERT,
  REMOVE_SESSION_ALERT,
  REMOVE_ALL_SESSION_ALERTS,
} from "../typesSessions";

import { isArray } from "../../utils/functionsCommon";
import { updateItemArray } from "../../utils/functionsState";

let idSet;

const deleteItemFromArray = (itemArray, itemToDeleteId) =>
  itemArray.filter((record) => record._id !== itemToDeleteId);

const deleteItemsFromArray = (itemArray, itemsToDeleteId) => {
  return itemArray.filter(
    (record) => record?._id && !itemsToDeleteId.includes(record._id)
  );
};

const sessionReducer = (state, action) => {
  switch (action.type) {
    case SET_SESSION_ALERT:
      return {
        ...state,
        sessionAlerts: !state.sessionAlerts
          ? [action.payload]
          : [...state.sessionAlerts, action.payload],
      };
    case REMOVE_SESSION_ALERT:
      return {
        ...state,
        sessionAlerts: state.sessionAlerts.filter(
          (alert) => alert.id !== action.payload
        ),
      };
    case REMOVE_ALL_SESSION_ALERTS:
      return {
        ...state,
        sessionAlerts: [],
      };

    case GET_SESSION_COUNT:
      return {
        ...state,
        sessionCount: action.payload,
      };

    case GET_SESSION:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };

    case GET_SESSIONS:
      idSet =
        state.filterOn && state.filtered
          ? state.filtered.map((item) => item._id)
          : [];

      return {
        ...state,
        sessions: action.payload,
        filtered:
          state.filtered && state.filterOn && action?.payload?.length
            ? action.payload.filter((item) => idSet.includes(item._id))
            : [],
        loading: false,
      };

    case ADD_SESSION:
      return {
        ...state,
        sessions: [action.payload, ...state.sessions],
        loading: false,
      };

    case UPDATE_SESSION:
      return {
        ...state,
        sessions: updateItemArray(state.sessions, action.payload, "_id"),
        filtered:
          state.filtered && state.filterOn
            ? updateItemArray(state.filtered, action.payload, "_id")
            : false,
        loading: false,
      };

    case DELETE_SESSION:
      return {
        ...state,
        sessions: deleteItemFromArray(state.sessions, action.payload),
        filtered:
          state.filtered && state.filterOn
            ? deleteItemFromArray(state.filtered, action.payload)
            : false,
        loading: false,
      };

    case DELETE_SESSIONS:
      return {
        ...state,
        sessions: deleteItemsFromArray(state.sessions, action.payload),
        filtered:
          state.filtered && state.filterOn
            ? deleteItemsFromArray(state.filtered, action.payload)
            : false,
        loading: false,
      };

    case SET_CURRENT_SESSION:
      return {
        ...state,
        current: action.payload,
      };

    case SET_DELETE_SESSION:
      return {
        ...state,
        deleteId: action.payload,
      };

    case CLEAR_DELETE_SESSION:
      return {
        ...state,
        deleteId: null,
      };

    case CLEAR_CURRENT_SESSION:
      return {
        ...state,
        current: null,
      };

    // case COPY_SESSION_PERIOD:
    //   return {
    //     ...state,
    //     sessions: action.payload,
    //     // as above state is being passed and state is immutable, need to make a copy
    //   };

    // case GET_IMPORT_TEMPLATE:
    //   return {
    //     ...state,
    //     import_template: action.payload,
    //     // sessions: state.sessions,
    //     // as above state is being passed and state is immutable, need to make a copy
    //   };

    // case FILTER_SESSION:
    //   return {
    //     ...state,
    //     filtered: state.sessions.filter((session) =>
    //       checkIfMatches(session, action, SessionFilterFields)
    //     ),
    //     filterOn: true,
    //     filters: action?.payload?.criteria || [],
    //   };

    // case CLEAR_FILTER_SESSION:
    //   return {
    //     ...state,
    //     filtered: null,
    //     filterOn: false,
    //     filters: null,
    //   };

    case SESSION_ERROR:
      return {
        ...state,
        error: isArray(state.error)
          ? [action.payload, ...state.error]
          : [action.payload],
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
      };

    default:
      return state;
  }
};

export default sessionReducer;
