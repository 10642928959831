import { addSet, deleteSet, linkSets, updateSet } from "./sessionFunctions";

export const sessionUpdate = (e, session) => {
  let newSesh = { ...session };
  let ctl = e.target.name;
  let change = e.target.value;
  let ids = ctl.split(".");
  let toChange = ids[0];
  let exIdx = ids?.[1] || -1;
  let setChange =
    ids?.[3] || ["link-all", "link-set", "add-set"].includes(change);

  // console.log("---------------");
  // console.log("ExerciseId:", exIdx);
  // console.log("Change:", change);
  // console.log("setChange:", setChange);
  // console.log("ids:", ids);

  if (setChange) {
    let sets = newSesh.exercises[exIdx].sets;
    let setId = ids[3];
    let set = sets[setId];

    if (change === "link-set") {
      sets[setId].link = set?.link ? !set.link : true;
    } else if (change === "link-all") {
      sets = linkSets(sets, e.target.link);
    } else if (change === "add-set") {
      sets = addSet(sets);
    } else if (change === "delete-set") {
      sets = deleteSet(sets, setId);
    } else if (toChange === "exercise" && ids[2] === "set") {
      sets = updateSet(sets, setId, ids[4], change);
    }
    if (sets?.length > 0) newSesh.exercises[exIdx].sets = sets;
  } else if (["type", "object", "comments"].includes(ids[2])) {
    // update exercise details
    newSesh.exercises[exIdx][ids[2]] = e.target.value;
  } else if (toChange === "exercise" && change === "add") {
    // update exercise name
    newSesh.exercises[exIdx].name = { name: e.target.value, _id: "new" };
  } else if (toChange === "exercise" && ids[2] === "name") {
    // update exercise name
    newSesh.exercises[exIdx].name = { name: e.target.value, _id: "new" };
  } else if (toChange === "name") {
    // update session name
    newSesh.name = e.target.value;
  } else if (toChange === "session-date") {
    // update session date
    newSesh.date = e.target.value;
  }

  return newSesh;
};
