import React, { useReducer } from "react";
import axios from "axios";
import ExerciseContext from "./exerciseContext";
import exerciseReducer from "./exerciseReducer";
import { v4 as uuid } from "uuid";

import {
  GET_EXERCISE,
  GET_EXERCISES,
  GET_EXERCISE_COUNT,
  ADD_EXERCISE,
  DELETE_EXERCISE,
  UPDATE_EXERCISE,
  SET_CURRENT_EXERCISE,
  CLEAR_CURRENT_EXERCISE,
  SET_DELETE_EXERCISE,
  EXERCISE_ERROR,
  FILTER_EXERCISE,
  CLEAR_FILTER_EXERCISE,
  CLEAR_ERRORS,
  CLEAR_DELETE_EXERCISE,
  SET_EXERCISE_ALERT,
  REMOVE_EXERCISE_ALERT,
  REMOVE_ALL_EXERCISE_ALERTS,
  DELETE_EXERCISES,
} from "../typesExercises";
import { cleanFilters } from "../../utils/functionsCommon";
import PropTypes from "prop-types";

let debug = 0;
let db = debug >= 1;
let dp = "com.exercisestate";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const ExerciseState = (props) => {
  const initialState = {
    exerciseAlerts: [],
    exercises: null,
    exerciseCount: null,
    current: null,
    filtered: null,
    filterOn: false,
    filters: null,
    deleteId: null,
    error: [],
    import_template: null,
    import_result: null,
    exerciseTotals: null,
    totals: null,
    totalsDaysDue: null,
    report_data: {},
    report_adhoc_data: [],
    loading: true,
  };

  const [state, dispatch] = useReducer(exerciseReducer, initialState);

  // Set Alert
  const setExerciseAlert = (msg, type, timeout = 5000) => {
    const id = uuid();

    dispatch({
      type: SET_EXERCISE_ALERT,
      payload: { msg, type, id },
    });
    setTimeout(
      () =>
        dispatch({
          type: REMOVE_EXERCISE_ALERT,
          payload: id,
        }),
      timeout
    );
  };

  // Set Alert
  const clearExerciseAlert = () => {
    dispatch({
      type: REMOVE_ALL_EXERCISE_ALERTS,
    });
  };

  // Get Exercises
  const getExercise = async (id) => {
    let lm = dp + ".getExercise";
    try {
      if (debug >= 1) console.log(`${lm}:start`);
      let res = await axios.get(`/api/exercises/exercise/${id}`);

      dispatch({
        type: GET_EXERCISE,
        payload: res.data,
      });

      if (debug >= 1) console.log(`${lm}:end`);
    } catch (err) {
      dispatch({
        type: EXERCISE_ERROR,
        payload: err,
      });
    }
  };

  // Get Exercises
  const getExercises = async (filters, sort, start) => {
    let lm = dp + ".getExercises";
    try {
      if (debug >= 1) console.log(`${lm}:start`);
      if (filters) filters = cleanFilters(filters);

      let res = [];
      if (!start) start = 1;
      res = await axios.get(`/api/exercises/exercises`, {
        params: { filters: filters, sort: sort, start: start },
      });

      dispatch({
        type: GET_EXERCISES,
        payload: res.data,
      });

      if (debug >= 1) console.log(`${lm}.getExercises:end`);
    } catch (err) {
      dispatch({
        type: EXERCISE_ERROR,
        payload: err,
      });
    }
  };

  // Get Exercises
  const getExerciseCount = async (filters, sort) => {
    let lm = dp + ".getExercises";
    try {
      if (debug >= 1) console.log(`${lm}:start`);
      if (filters) filters = cleanFilters(filters);

      let res = [];
      res = await axios.get(`/api/exercises/count`, {
        params: { filters: filters, sort: sort },
      });

      dispatch({
        type: GET_EXERCISE_COUNT,
        payload: res.data,
      });

      if (debug >= 1) console.log(`${lm}.getExercises:end`);
    } catch (err) {
      dispatch({
        type: EXERCISE_ERROR,
        payload: err,
      });
    }
  };

  // Add Contact
  const addExercise = async (exercise) => {
    let lm = dp + ".addExercise";

    if (db) console.log(`${lm}`);

    await axios
      .post("/api/exercises", exercise, config)
      .then((response) => {
        dispatch({
          type: ADD_EXERCISE,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: EXERCISE_ERROR,
          payload: err,
        });
      });
  };

  // Update Contact
  const updateExercise = async (exercise) => {
    let lm = dp + ".updateExercise: ";

    try {
      const res = await axios.put(
        `/api/exercises/${exercise._id}`,
        exercise,
        config
      );

      dispatch({ type: UPDATE_EXERCISE, payload: res.data });
      setExerciseAlert("Data Saved", "success");
    } catch (err) {
      if (db) console.log(`${lm}:error: ${err.message}`);
      dispatch({
        type: EXERCISE_ERROR,
        payload: err,
      });
      setExerciseAlert("Error Saving Data", "danger");
    }
  };

  const deleteExercise = async (id) => {
    try {
      // try registering user with form data and json config
      await axios
        .post("/api/exercises/deleteExercise", { id: id }, config)
        .then((response) => {
          dispatch({
            type: DELETE_EXERCISE,
            payload: response.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: EXERCISE_ERROR,
            payload: err,
          });
        });
    } catch (err) {
      dispatch({
        type: EXERCISE_ERROR,
        payload: err,
      });
    }
  };

  const deleteExercises = async (deleteIds) => {
    try {
      await axios
        .post("/api/exercises/delete", { ids: deleteIds }, config)
        .then((response) => {
          dispatch({
            type: DELETE_EXERCISES,
            payload: response.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: EXERCISE_ERROR,
            payload: err,
          });
        });
    } catch (err) {
      dispatch({
        type: EXERCISE_ERROR,
        payload: err,
      });
    }
  };

  // Filter Contacts
  const filterExercises = (array) => {
    dispatch({
      type: FILTER_EXERCISE,
      payload: { criteria: array, filterOn: true },
    });
  };

  // Clear Filter
  const clearFilter = () => {
    dispatch({ type: CLEAR_FILTER_EXERCISE, payload: { filterOn: false } });
  };

  const setCurrentExercise = (exercise) => {
    dispatch({ type: SET_CURRENT_EXERCISE, payload: exercise });
  };

  const clearCurrentExercise = () => {
    dispatch({ type: CLEAR_CURRENT_EXERCISE });
  };

  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  const setDeleteExercise = (exercise) => {
    dispatch({ type: SET_DELETE_EXERCISE, payload: exercise });
  };

  const clearDeleteExercise = () => {
    dispatch({ type: CLEAR_DELETE_ACCOUNT });
  };

  return (
    <ExerciseContext.Provider
      value={{
        exerciseAlerts: state.exerciseAlerts,
        exercises: state.exercises,
        exerciseCount: state.exerciseCount,
        current: state.current,
        filtered: state.filtered,
        filters: state.filters,
        filterOn: state.filterOn,
        deleteId: state.deleteId,
        error: state.error,
        loading: state.loading,
        setExerciseAlert,
        clearExerciseAlert,
        getExercise,
        getExercises,
        getExerciseCount,
        addExercise,
        updateExercise,
        deleteExercise,
        deleteExercises,
        setCurrentExercise,
        clearCurrentExercise,
        setDeleteExercise,
        clearDeleteExercise,
        filterExercises,
        clearFilter,
        clearErrors,
      }}
    >
      {props.children}
    </ExerciseContext.Provider>
  );
};

ExerciseState.propTypes = {
  children: PropTypes.object,
};

export default ExerciseState;
