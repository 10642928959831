import {
  GET_EXERCISE,
  GET_EXERCISES,
  GET_EXERCISE_COUNT,
  ADD_EXERCISE,
  UPDATE_EXERCISE,
  DELETE_EXERCISE,
  DELETE_EXERCISES,
  SET_CURRENT_EXERCISE,
  CLEAR_CURRENT_EXERCISE,
  EXERCISE_ERROR,
  CLEAR_ERRORS,
  SET_DELETE_EXERCISE,
  CLEAR_DELETE_EXERCISE,
  SET_EXERCISE_ALERT,
  REMOVE_EXERCISE_ALERT,
  REMOVE_ALL_EXERCISE_ALERTS,
} from "../typesExercises";

import { isArray } from "../../utils/functionsCommon";
import { updateItemArray } from "../../utils/functionsState";

let idSet;

const deleteItemFromArray = (itemArray, itemToDeleteId) =>
  itemArray.filter((record) => record._id !== itemToDeleteId);

const deleteItemsFromArray = (itemArray, itemsToDeleteId) => {
  return itemArray.filter(
    (record) => record?._id && !itemsToDeleteId.includes(record._id)
  );
};

const exerciseReducer = (state, action) => {
  switch (action.type) {
    case SET_EXERCISE_ALERT:
      return {
        ...state,
        exerciseAlerts: !state.exerciseAlerts
          ? [action.payload]
          : [...state.exerciseAlerts, action.payload],
      };
    case REMOVE_EXERCISE_ALERT:
      return {
        ...state,
        exerciseAlerts: state.exerciseAlerts.filter(
          (alert) => alert.id !== action.payload
        ),
      };
    case REMOVE_ALL_EXERCISE_ALERTS:
      return {
        ...state,
        exerciseAlerts: [],
      };

    case GET_EXERCISE_COUNT:
      return {
        ...state,
        exerciseCount: action.payload,
      };

    case GET_EXERCISE:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };

    case GET_EXERCISES:
      idSet =
        state.filterOn && state.filtered
          ? state.filtered.map((item) => item._id)
          : [];

      return {
        ...state,
        exercises: action.payload,
        filtered:
          state.filtered && state.filterOn && action?.payload?.length
            ? action.payload.filter((item) => idSet.includes(item._id))
            : [],
        loading: false,
      };

    case ADD_EXERCISE:
      return {
        ...state,
        exercises: [action.payload, ...state.exercises],
        loading: false,
      };

    case UPDATE_EXERCISE:
      return {
        ...state,
        exercises: updateItemArray(state.exercises, action.payload, "_id"),
        filtered:
          state.filtered && state.filterOn
            ? updateItemArray(state.filtered, action.payload, "_id")
            : false,
        loading: false,
      };

    case DELETE_EXERCISE:
      return {
        ...state,
        exercises: deleteItemFromArray(state.exercises, action.payload),
        filtered:
          state.filtered && state.filterOn
            ? deleteItemFromArray(state.filtered, action.payload)
            : false,
        loading: false,
      };

    case DELETE_EXERCISES:
      return {
        ...state,
        exercises: deleteItemsFromArray(state.exercises, action.payload),
        filtered:
          state.filtered && state.filterOn
            ? deleteItemsFromArray(state.filtered, action.payload)
            : false,
        loading: false,
      };

    case SET_CURRENT_EXERCISE:
      return {
        ...state,
        current: action.payload,
      };

    case SET_DELETE_EXERCISE:
      return {
        ...state,
        deleteId: action.payload,
      };

    case CLEAR_DELETE_EXERCISE:
      return {
        ...state,
        deleteId: null,
      };

    case CLEAR_CURRENT_EXERCISE:
      return {
        ...state,
        current: null,
      };

    case EXERCISE_ERROR:
      return {
        ...state,
        error: isArray(state.error)
          ? [action.payload, ...state.error]
          : [action.payload],
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
      };

    default:
      return state;
  }
};

export default exerciseReducer;
