// EXERCISES CONSTS
export const GET_EXERCISE = "GET_EXERCISE";
export const GET_EXERCISES = "GET_EXERCISES";
export const GET_EXERCISE_COUNT = "GET_EXERCISE_COUNT";
export const GET_EXERCISE_TOTALS = "GET_EXERCISE_TOTALS";
export const GET_TOTALS_DAYSDUE = "GET_TOTALS_DAYSDUE";
export const GET_TOTALS = "GET_TOTALS";
export const ADD_EXERCISE = "ADD_EXERCISE";
export const DELETE_EXERCISE = "DELETE_EXERCISE";
export const DELETE_EXERCISES = "DELETE_EXERCISES";
export const SET_CURRENT_EXERCISE = "SET_CURRENT_EXERCISE";
export const CLEAR_CURRENT_EXERCISE = "CLEAR_CURRENT_EXERCISE";
export const SET_DELETE_EXERCISE = "SET_DELETE_EXERCISE";
export const CLEAR_DELETE_EXERCISE = "CLEAR_DELETE_EXERCISE";
export const UPDATE_EXERCISE = "UPDATE_EXERCISE";
export const FILTER_EXERCISE = "FILTER_EXERCISE";
export const CLEAR_FILTER_EXERCISE = "CLEAR_FILTER_EXERCISE";
export const EXERCISE_ERROR = "EXERCISE_ERROR";
export const IMPORT_EXERCISE = "IMPORT_EXERCISE";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const SET_EXERCISE_ALERT = "SET_EXERCISE_ALERT";
export const REMOVE_EXERCISE_ALERT = "REMOVE_EXERCISE_ALERT";
export const REMOVE_ALL_EXERCISE_ALERTS = "REMOVE_ALL_EXERCISE_ALERTS";
